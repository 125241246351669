import { ReactNode, useRef, useState } from "react";
import { useOutsideClick } from "~/lib/utils";
import Button from "./button";
import { IoMdClose } from "react-icons/io";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLElement>();

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
    triggerRef.current?.focus();
  }

  return {
    triggerProps: {
      ref: triggerRef,
      onClick: open,
    },
    modalProps: {
      open: isOpen,
      onOpenChange: (value: boolean) => {
        if (value) open();
        else close();
      },
    },
    open,
    close,
  };
}

interface ModalProps {
  children: ReactNode;
  open?: boolean;
  onOpenChange?: (value: boolean) => void;
  closeable?: boolean;
}

export function Modal({
  children,
  open,
  onOpenChange = () => {},
  closeable = true,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(modalRef, () => {
    if (closeable) {
      console.log("Closing modal due to outside click");
      onOpenChange(false);
    }
  });

  return (
    open && (
      <div className="fixed top-0 left-0 w-full h-dvh z-50 bg-opacity-30 bg-black  flex flex-col items-center justify-center p-6 overflow-y-auto">
        <div
          className="absolute bg-background text-primary min-w-64 rounded-md shadow-md border-border "
          ref={modalRef}
        >
          {/* Close Button */}
          <Button
            variant="ghost"
            className="absolute top-3 right-3 p-2  hover:bg-red-600 w-15 h-10 rounded-full bg-background text-text border-0"
            onClick={() => onOpenChange(false)}
            aria-label="Close"
          >
            <IoMdClose size={24} />
          </Button>
          <div>{children}</div>
        </div>
      </div>
    )
  );
}
